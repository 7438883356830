<template>
  <div>
    <Header />
    <Search />
    <div class="ding">
      <div class="dingdan">
        <div class="ding_L">
          <div class="wenzi">
            <div class="wenzi_T">
              <div class="tijiao" v-if="isBuy==false">订单提交成功，请尽快付款！</div>
              <div class="tijiao" v-else>订单提交成功，请尽快联系会员付款！</div>
              <div class="danhao">流水号：{{ istrue=='1'?orderId:mergeOrderNo }}</div>
            </div>
            <div class="wenzi_B">
              请尽快完成支付，否则订单会被自动取消
            </div>
          </div>
        </div>
        <div class="ding_R">
          <div class="yingfu" v-if="jifen == 1">
            应付积分：<span class="price">￥{{ useScore }}</span>
          </div>
          <div class="yingfu" v-else>
            应付金额<span>：</span><span class="price">￥{{ sumPrice }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="way">
      <div class="way_conten" v-if="isBuy==false">
        <div class="pay" v-if="jifen == 1">
          <div class="duigong">
            <div class="pay_weixin" @click="fukuan2">
              <img src="../../assets/zhifu/transfer.png" alt="" />
              <span>积分支付</span>
            </div>
          </div>
        </div>
        <div v-else class="pay">
<!--          <div class="pay_weixin">-->
<!--            <img src="../../assets/zhifu/zfb.png" alt="" />-->
<!--            <span>支付宝支付</span>-->
<!--          </div>-->
<!--          <div class="pay_weixin" @click="pay_wechat">-->
<!--            <img src="../../assets/zhifu/wx.png" alt="" />-->
<!--            <span>微信支付</span>-->
<!--          </div>-->
          <div class="duigong">
            <div class="pay_weixin" @click="pay_transfer">
              <img src="../../assets/zhifu/transfer.png" alt="" />
              <span>对公打款</span>
            </div>
          </div>
         <div class="duigong">
           <div class="pay_weixin" @click="pay_balance">
             <img src="../../assets/zhifu/balance.png" alt="" />
             <span>余额支付</span>
           </div>
         </div>
         <div class="duigong" v-show="shouxin">
           <div class="pay_weixin" @click="pay_creditline">
             <img src="../../assets/zhifu/creditline.png" alt="" />
             <span>信用支付</span>
             <span>当前剩余(￥{{ creditLine }})</span>
           </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <Baoyou/> -->
    <div class="kongbai"></div>
    <Foot />
  </div>
</template>
<script>
import Header from '@/components/header.vue';
import { getPayCompanyInfo } from '@/api/company';
import { get, post } from '@/utils/request';
import Foot from '@/components/foot.vue';
import Search from '@/components/order/buy_succeed/search.vue';
import Baoyou from '@/components/order/baoyou.vue';
import { baseURL } from '@/utils/request';
import QRCode from 'qrcodejs2';
export default {
  components: {
    Header,
    Foot,
    Search,
    Baoyou,
  },
  data() {
    return {
      mergeOrderNo: null,
      sumPrice: 0,
      sign: null,
      info: {},
      shouxin: false,
      creditLine: 0,
      jifen: '',
      buyjifen: '',
      exchangeScore: '',
      skuId: '',
      spuId: '',
      num: '',
      dialogVisible: false,
      dialogVisible1: false,
      imageUrl: '',
      picurl: baseURL + 'f/api/frontCommon/payVoucher',
      orderId: '',
      price: '',
      istrue:'2',
      isBuy:false,
      useScore:null,
    };
  },
  created() {
    // this.skuId = this.$route.params.skuId;
    // this.spuId = this.$route.params.spuId;
    this.jifen = this.$route.query.jifen;
    // this.num = this.$route.params.num;
    // if (this.$route.params.sumPrice) {
    //   this.sumPrice = this.$route.params.sumPrice;
    // } else if (this.$route.params.orderAmount) {
    //   this.sumPrice = this.$route.params.orderAmount;
    // }
    // this.sign = this.$route.params.sign;
    // getPayCompanyInfo().then((res) => {
    //   if (res.data) {
    //     this.info = res.data;
    //   }
    // });

    this.isBuy = this.$route.query.isBuy;
    this.orderId = this.$route.query.orderId;
    this.price = this.$route.params.price;
    this.sumPrice = this.$route.params.price;

    this.getcreditisopen();
    this.getjifen(this.orderId);
    this.istrue = this.$route.query.istrue;
    if (this.istrue=='1'){
      this.mergeOrderNo =this.$route.query.orderId;
    }else {
      this.getdetail(this.$route.query.orderId);
    }
  },
  methods: {
    /**
     * 微信扫码支付
     */
    pay_wechat() {
      this.$router.push({
        name: 'Pay-weixin',
        params: {
          price: this.price,
        },
        query: {
          orderId: this.orderId,
          istrue:this.istrue
        },
      });
    },
    /**
     * 微信扫码支付
     */
    pay_wechat1() {
      this.$router.push({
        name: 'Pay-weixin',
        params: {
          price: this.price,
        },
        query: {
          orderId: this.orderId,
        },
      });
    },
    /**
     * 对公转账支付
     */
    pay_transfer() {
      this.$router.push({
        name: 'Pay-transfer',
        params: {
          price: this.price,
        },
        query: {
          orderId: this.orderId,
          istrue:this.istrue
        },
      });
    },
    /**
     * 余额支付
     */
    pay_balance() {
      this.$router.push({
        name: 'Pay-balance',
        params: {
          price: this.price,
        },
        query: {
          orderId: this.orderId,
          istrue:this.istrue
        },
      });
    },
    /**
     * 授信额度支付
     */
    pay_creditline() {
      if (Number(this.creditLine) < Number(this.sumPrice)) {
        this.$message.error({
          message: '授信额度不足',
        });
      } else {
        this.$confirm('是否使用信用额度进行支付?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          if (this.istrue == '1') {
            get('api/orderbasic/updateOrderPayByCreditLine?mergeOrderNo=' +
                    this.orderId +
                    '&isTrue=1').then(
                    (res) => {
                      if (res.data.data == 1) {
                        this.$message.success({
                          message: '支付成功',
                        });
                        this.$router.push({
                          name: 'Buy_succeed',
                        });
                      } else {
                        this.$message.error({
                          message: res.data.msg,
                        });
                      }
                    }
            );
          }else {
            get('api/orderbasic/updateOrderPayByCreditLine?orderNo=' +
                    this.mergeOrderNo +
                    '&isTrue=2').then(
                    (res) => {
                      if (res.data.data == 1) {
                        this.$message.success({
                          message: '支付成功',
                        });
                        this.$router.push({
                          name: 'Buy_succeed',
                        });
                      } else {
                        this.$message.error({
                          message: res.data.msg,
                        });
                      }
                    }
            );
          }
        });
      }
    },
    /**
     * 积分支付
     */
    fukuan2() {
      post('api/account/setMemberScore', {
        scoreValue: this.useScore,
        changeType: 1,
        sourceType: 3,
        sourceRemark: "订单号" + this.orderId,
      }).then((res) => {
        if (res.data.msg == '积分不足') {
          this.$message.error({
            message: res.data.msg,
          });
        } else if (res.data.msg == '积分更新成功') {
          this.$message.success({
            message: '兑换成功',
          });

          //更新订单状态
          get('api/orderbasic/updateOrderStatus?orderNo=' +
            this.orderId).then(
            (res) => {
              if (res.data.data == 1) {
                //推送订单消息给店铺管理员
                

                this.$router.push({
                  name: 'Buy_succeed',
                });
              } else {
                this.$message.error({
                  message: res.data.msg,
                });
              }
            }
          );
        } else if (res.data.msg == '积分更新失败') {
          this.$message.error({
            message: '兑换失败',
          });
        }
      });
    },
    /**
     * 查询订单支付单号
     */
    getdetail(e) {
      get('api/orderbasic/getorderbyid?orderId=' + e + '').then((res) => {
        this.mergeOrderNo = res.data.data.orderNo;
        //this.sumPrice = res.data.data.orderAmount;
      });
    },
    /**
     * 根据商品ID查询可兑换的商品信息
     */
    getjifen(e) {
      get(
        'api/storeCategory/getExchangeProductByOrderNo?orderNo=' + e + ''
      ).then((res) => {
       this.useScore =res.data.data.useScore
      });
    },
    /**
     * 查询当前登录人是否开通授信额度
     */
    getcreditisopen() {
      get('api/credit/getcreditisopen').then((res) => {
        this.shouxin = res.data.data;
        if (res.data.data == true) {
          this.getcreditnum();
        }
      });
    },
    /**
     * 查询当前登录人的授信额度
     */
    getcreditnum() {
      get('api/credit/getcreditnum').then((res) => {
        this.creditLine = res.data.data.creditsurplus;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.way {
  width: 100%;
  height: 300px;
  margin: auto;
  background-color: #f5f5f5;
  color: #666;
  .way_conten {
    width: 900px;
    height: 100%;
    background: #fff;
    margin: auto;
    .pay {
      height: 100%;
      padding: 50px;
      .pay_weixin {
        display: flex;
        align-items: center;
        img {
          width: 50px;
          height: 50px;
        }
        span {
          font-weight: 600;
          font-size: 25px;
          margin-left: 20px;
          cursor: pointer;
        }
      }
    }
    .duigong {
      font-weight: 600;
      font-size: 25px;
      margin-top: 10px;
      margin-left: 0px;
      .pay_weixin {
        display: flex;
        align-items: center;
        img {
          width: 50px;
          height: 50px;
        }
        span {
          font-weight: 600;
          font-size: 25px;
          margin-left: 20px;
          cursor: pointer;
        }
      }
    }
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 400px;
  height: 200px;
}
.company-info-wrapper {
  background-color: #f5f5f5;
}

.company-info {
  width: 900px;
  padding: 40px;
  margin: 0 auto;
  background-color: #fff;
}

.company-info h4 {
  margin: 10px 0 30px 0;
  color: #666;
  font-size: 16px;
  font-weight: normal;
}

.company-info h4 span {
  color: #1950ff;
  font-weight: bold;
}

.company-info table {
  font-size: 16px;
  border-left: 1px solid #eee;
  border-top: 1px solid #eee;
  width: 100%;
}

.company-info table tr td:first-child {
  font-weight: bold;
}

.company-info table tr:first-child {
  background-color: #f6f6f6;
}

.company-info table td {
  padding: 10px 20px;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
.warn-info h4 {
  font-weight: bold;
  font-size: 14px;
  color: #000;
  margin: 20px 0 10px 0;
}
.warn-info p {
  font-size: 14px;
  color: #333;
  margin: 0 0 10px 0;
}
</style>

<style lang="scss" scoped>
.ding {
  width: 100%;
  background: #f5f5f5;

  .dingdan {
    width: 1240px;
    height: 123px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .ding_L {
      display: flex;

      .erweima {
        width: 95px;
        height: 90px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .wenzi {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 20px;

        .wenzi_T {
          font-size: 16px;
          display: flex;

          .tijiao {
            color: #3e3e3e;
          }

          .danhao {
            color: #282828;
          }
        }

        .wenzi_B {
          font-size: 14px;
          margin-top: 15px;
          color: #3f3f3f;

          .red {
            color: #e41412;
          }
        }
      }
    }

    .ding_R {
      display: flex;
      flex-direction: column;

      .yingfu {
        color: #333;
        font-size: 16px;

        .price {
          font-size: 16px;
          color: #e53739;
        }
      }

      .detail {
        font-size: 14px;
        color: #1b50fe;
        margin-top: 15px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}

.fangshi {
  width: 100%;
  background: #f5f5f5;

  .fang {
    width: 1207px;
    background: #fff;
    margin: 0 auto;
    padding-left: 30px;

    .zhifu {
      font-size: 24px;
      color: #606060;
      font-weight: 600;
      height: 97px;
      line-height: 97px;
    }

    .xuanze {
      margin-top: 40px;
      padding-bottom: 38px;

      .list:hover {
        border: 1px solid #ddd;
      }

      .list1:hover {
        border: 1px solid #ddd;
      }

      .list {
        width: 1053px;
        height: 98px;
        border-right: 1px solid #fff;
        border-left: 1px solid #fff;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #ddd;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        padding: 0 36px 0 90px;
        align-items: center;

        .list_L {
          display: flex;
          align-items: center;

          .logo {
            width: 34px;
            height: 29px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .wei_zhi {
            font-size: 20 xp;
            color: #323232;
            margin-left: 12px;
          }

          .huodong {
            margin-left: 130px;
            font-size: 14px;
            color: #555;
          }

          .youhui {
            width: 40px;
            height: 19px;
            color: #fefefe;
            background: #e31613;
            line-height: 19px;
            font-size: 14px;
            border-radius: 5px;
            text-align: center;
            margin-left: 130px;
          }

          .jine {
            margin-left: 5px;
          }

          .xiahong {
            margin-left: 5px;
            width: 30px;
            height: 30px;

            .img {
              width: 6px;
              height: 4px;
            }
          }
        }
      }

      .list1 {
        width: 1053px;
        height: 132px;
        border-right: 1px solid #fff;
        border-left: 1px solid #fff;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #ddd;
        border-radius: 10px;
        display: flex;
        padding: 50px 0 0 90px;
        align-items: flex-start;

        .list1_L {
          display: flex;
          align-items: center;

          .logo1 {
            width: 34px;
            height: 29px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .shouxin {
            font-size: 20 xp;
            color: #323232;
            margin-left: 12px;
          }
        }

        .list1_R {
          margin-left: 86px;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .huo_T {
            display: flex;
            align-items: center;

            .youhui1 {
              width: 40px;
              height: 19px;
              color: #e31613;
              background: #fff;
              line-height: 19px;
              font-size: 14px;
              border-radius: 5px;
              text-align: center;
              border: 1px solid #e31613;
            }

            .jine1 {
              margin-left: 5px;
            }
          }

          .huo_B {
            display: flex;

            .fenqi {
              width: 114px;
              height: 73px;
              border: 2px solid #4bbfb6;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              margin-top: 10px;
              margin-right: 20px;

              .fenqi_T {
                font-size: 16px;
                color: #333;
              }

              .fenqi_B {
                font-size: 14px;
                color: #555;
              }
            }
          }
        }
      }
    }
  }
}
</style>
